<template>
  <v-container fluid>
    <v-row class="ml-5">
      <v-col cols="12" md="4">
        <v-switch
          v-model="newCategory.isHomeAvailable"
          label="Aparece en lobby"
        >
        </v-switch>
      </v-col>
<!-- 
      <v-col cols="12" md="4">
        <v-switch v-model="newCategory.isCongested" label="Categoría saturada">
        </v-switch>
      </v-col> -->

      <!-- <v-col cols="12" md="4">
        <v-switch v-model="newCategory.kmApply" label="Aplica cobro por envío">
        </v-switch>
      </v-col> -->
      <!-- <v-col cols="12" md="4">
        <v-switch
          v-model="newCategory.showTime"
          label="Muestra tiempo promedio"
        >
        </v-switch>
      </v-col> -->

      <!-- <v-col cols="12" md="4">
        <v-switch v-model="newCategory.showFavorite" label="Mostrar favoritos">
        </v-switch>
      </v-col> -->

      <!-- <v-col cols="12" md="4">
        <v-switch v-model="newCategory.hideName" label="Ocultar nombre">
        </v-switch>
      </v-col> -->

      <!-- <v-col cols="12" md="4">
        <v-switch v-model="newCategory.hideBackground" label="Ocultar fondo">
        </v-switch>
      </v-col> -->

      <v-col cols="12" md="4">
        <v-switch v-model="newCategory.showTags" label="Mostrar Tags">
        </v-switch>
      </v-col>

      <!-- <v-col cols="12" md="4">
        <v-switch v-model="newCategory.closed" label="Cerrado"> </v-switch>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["newCategory"],

  name: "category-status",
};
</script>