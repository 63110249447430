<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Secciones de {{ category.name }}</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text
        style="max-height: 65vh; min-height: 65vh; overflow-y: scroll"
      >
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="7" sm="7">
              <v-text-field
                v-model="search"
                clearable
                rounded
                flat
                filled
                hide-details
                :label="`Buscar sección`"
                prepend-inner-icon="fa-search"
                class="d-none d-md-flex"
              />
            </v-col>
            <v-col cols="12" md="5" sm="5">
              <v-row justify="end">
                <v-btn
                  color="primary"
                  class="ma-2 white--text mr-5"
                  @click="modalSort = true"
                  :loading="loading"
                >
                  <v-icon right dark class="mr-3">fas fa-bars</v-icon>
                  Ordenar
                </v-btn>

                <v-btn
                  color="primary"
                  class="ma-2 white--text"
                  @click="
                    modalAddSection = true;
                    randomKey = Math.random();
                  "
                  :loading="loading"
                >
                  <v-icon right dark class="mr-3">fas fa-plus</v-icon>
                  Crear nueva sección
                </v-btn>
              </v-row>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="sections"
            :search="search"
            :items-per-page="5"
            fixed-header
            :loading="loading"
            :sort-by="['position']"
            class="mt-5"
            :sort-desc="[false, true]"
            item-key="id"
            :footer-props="{
              itemsPerPageOptions: [5],
            }"
          >
            <template v-slot:[`item.active`]="{ item }">
              <div style="display: flex ; justify-content: center;!important">
                <v-switch
                  @change="
                    switchControlChanged(
                      { active: item.active },
                      item['.key'],
                      item
                    )
                  "
                  v-model="item.active"
                ></v-switch>
              </div>
            </template>

            <template v-slot:[`item.options`]="{ item }">
              <v-btn @click="editSection(item)" small color="primary"
                >Editar</v-btn
              >
              <v-btn
                class="ml-5 white--text"
                @click="
                  itemToDelete = item;
                  modalDelete = true;
                "
                small
                color="red darken-4"
                >Eliminar</v-btn
              >
            </template>

            <template v-slot:loading>
              <div class="ma-5" style="position: relative">
                <div
                  style="
                    position: absolute;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <lottie
                    :options="defaultOptions"
                    :height="300"
                    :width="400"
                    v-on:animCreated="handleAnimation"
                  />
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                ></v-skeleton-loader>
              </div>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog
      persistent
      v-if="sectionSelected"
      v-model="modalEditSection"
      max-width="800px"
    >
      <edit-section
        :key="sectionSelected['.key']"
        @success="handleUpdate"
        @cancel="modalEditSection = false"
        :section="sectionSelected"
        :category="category"
      ></edit-section>
    </v-dialog>

    <v-dialog persistent v-model="modalAddSection" max-width="800px">
      <add-section
        :sectionsLength="sections.length"
        @success="handleCreate"
        :key="randomKey"
        @cancel="modalAddSection = false"
        :category="category"
      ></add-section>
    </v-dialog>

    <v-dialog v-model="modalSort" max-width="500px">
      <sort
        @success="handleSort"
        :key="`sort-${randomKey}`"
        @cancel="modalSort = false"
        :categoryKey="category['.key']"
        :sections="sections"
      ></sort>
    </v-dialog>

    <v-dialog max-width="400" v-model="modalDelete" v-if="itemToDelete">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar {{ itemToDelete.name }}?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../../components/Lottie";
import * as animationData from "../../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";
// import editUserSkeleton from '../../skeleton/edit-user-skeleton'
import editSection from "./edit-section";
import addSection from "./add-section";
import sort from "./sort";

export default {
  name: "sections",
  props: ["category"],
  components: {
    lottie: Lottie,
    // editUserSkeleton,
    editSection,
    addSection,
    sort,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      snackbarText: "",
      saving: false,
      loading: true,
      sectionSelected: null,
      modalEditSection: false,
      modalSort: false,
      modalAddSection: false,
      modalDelete: false,
      itemToDelete: null,
      search: null,
      sections: [],
      randomKey: 0,
      headers: [
        { text: "Posición", value: "position" },
        { text: "Nombre", value: "name" },
        {
          text: "Visible en el app",
          value: "active",
          align: "center",
          justify: "center",
        },
        { text: "Máximo permitido", value: "maxAllowed" },
        { text: "Ordenar por", value: "orderBy" },
        { text: "Número", value: "number" },
        { text: "Operador", value: "operator" },
        { value: "options", align: "end", width: "250px" },
      ],
    };
  },

  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    handleCreate() {
      this.getSections();
      this.modalAddSection = false;
      this.snackbarText = "Información actualizada correctamente.";
      this.snackbar = true;
    },
    handleUpdate() {
      this.getSections();
      this.modalEditSection = false;
      this.snackbarText = "Información actualizada correctamente.";
      this.snackbar = true;
    },

    handleSort() {
      this.getSections();
      this.modalSort = false;
      this.snackbarText = "Posiciones actualizadas correctamente.";
      this.snackbar = true;
    },
    getSections() {
      this.sections = [];
      this.loading = true;
      db.collection(`categories/${this.category[".key"]}/sections`)
        .where("deleted", "==", false)
        .get()
        .then((response) => {
          response.forEach((item) => {
            let section = item.data();
            section[".key"] = item.id;
            this.sections.push(section);
          });
          this.loading = false;
        });
    },
    confirmDelete() {
      if (this.itemToDelete) {
        db.collection(`categories/${this.category[".key"]}/sections`)
          .doc(this.itemToDelete[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: new Date(),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.modalDelete = false;
            this.sections = this.sections.filter(
              (item) => item[".key"] == this.itemToDelete[".key"]
            );
            this.snackbar = true;
            this.snackbarText = "Categoría eliminada";
          })
          .catch((error) => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },

    switchControlChanged(data, sectionId, section) {
      if (data && sectionId) {
        this.saving = true;
        db.collection(`categories/${this.category[".key"]}/sections`)
          .doc(sectionId)
          .update({
            active: data.active,
          })
          .then((ref) => {
            this.saving = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            section.active = !section.active;
          });
      }
    },
    editSection(section) {
      this.sectionSelected = section;
      this.modalEditSection = true;
    },
  },
  mounted() {
    this.getSections();
  },
};
</script>
