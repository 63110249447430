<template>
  <v-card>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>
    <v-card-title class="grid-close">
      <span class="headline">
        {{ type == "edit" ? "Editar" : "Nueva" }} categoría
        {{ newCategory.name }}
      </span>
      <v-btn
        style="border-radius: 10px"
        icon
        right
        top
        color="primary"
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-tabs v-model="tab" align-with-title color="primary">
      <v-tab key="general"> General </v-tab>
      <v-tab key="tags">Etiquetas </v-tab>
      <!-- <v-tab key="km">Configuración de KM </v-tab> -->
      <!-- <v-tab key="fees">Tarifas adicionales</v-tab> -->
      <!-- <v-tab key="schedule"> Horarios </v-tab> -->
      <v-tab key="state"> Estados </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-card-text>
      <v-tabs-items v-model="tab">
        <v-tab-item key="general">
          <General @imageSelected="setImageFile" :newCategory="newCategory" />
        </v-tab-item>

        <v-tab-item key="tags">
          <Tags
            @changed="tagsChanged"
            :country="country"
            ref="tags"
            :newCategory="newCategory"
            :allTags="allTags"
          />
        </v-tab-item>

        <!-- <v-tab-item key="km">
          <Km :newCategory="newCategory" />
        </v-tab-item> -->

        <!-- <v-tab-item key="fees">
          <Fees @change="extraFeesChanged" :newCategory="newCategory" />
        </v-tab-item> -->

        <!-- <v-tab-item key="schedule">
          <Schedule :schedule="scheduleCopy" />
        </v-tab-item> -->

        <v-tab-item key="state">
          <Status :newCategory="newCategory" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions v-if="!loading">
      <v-row class="pa-0 ma-0">
        <v-col cols="6" xs="6" sm="6" md="6">
          <v-row align="center" justify="start">
            <v-switch v-model="newCategory.active" label="Categoría activa">
            </v-switch>
          </v-row>
        </v-col>

        <v-col cols="6" xs="6" sm="6" md="6">
          <v-row align="center" justify="end">
            <v-btn
              v-if="type == 'edit'"
              @click="updateCategory()"
              class="save-btn mt-4"
              color="primary"
            >
              Guardar
            </v-btn>
            <v-btn
              v-else
              @click="addCategory()"
              class="save-btn mt-4"
              color="primary"
            >
              Guardar
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";
import General from "./tabs/general.vue";
import Km from "./tabs/km.vue";
import Fees from "./tabs/extra-fees.vue";
import Status from "./tabs/status.vue";
import Schedule from "@/components/own-schedule";
import Tags from "./tabs/tags.vue";

export default {
  name: "new-category",
  props: ["city", "type", "editedCategory", "country"],
  components: {
    lottie: Lottie,
    General,
    Km,
    Fees,
    Status,
    Schedule,
    Tags,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      tab: "",
      snackbarText: "",
      saving: false,
      loading: true,
      selectedTags: [],
      allTags: [],
      scheduleCopy: {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      },
      newCategory: {},
      imageFile: null,
      fullImageFile: null,
    };
  },
  async mounted() {
    if (this.type == "edit") {
      this.newCategory = Object.assign({}, this.editedCategory);

      this.scheduleCopy = this.newCategory.schedule
        ? this.newCategory.schedule
        : this.scheduleCopy;
    }

    await this.$binding(
      "allTags",
      db.collection("countries").doc(this.country).collection("tagsOVive")
    );

    this.loading = false;
  },
  computed: {
    ...mapState(["selectedEvent", "user"]),
    validate() {
      if (!this.newCategory.name ) {
        this.snackbarText = "Debe llenar todos los campos.";
        this.snackbar = true;
        return false;
      }
      
      else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions(["visibleSearch", "Alert_", "addBusiness", "_searchTerm"]),
    moment,
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    extraFeesChanged(e) {
      this.newCategory.extraFees = e;
    },

    setImageFile(e) {
     if (e.imageType == "fullImage") {
        this.fullImageFile = e.fullImageFile;
      }
    },
    tagsChanged(e) {
      this.selectedTags = e;
    },
    updateTags(categoryId) {
      return new Promise(async (resolve, reject) => {
        if (this.$refs.tags) {
         // console.debug(this.allTags);
          for (let i = 0; i < this.allTags.length; i++) {
            const tagId = this.allTags[i][".key"];
            const tag = this.allTags[i];

            let reference = db
              .collection("countries")
              .doc(this.country)
              .collection("tagsOVive")
              .doc(tagId);

            if (
              (!tag.categories && this.selectedTags.includes(tagId)) ||
              (this.selectedTags.includes(tagId) &&
                !tag.categories.includes(categoryId))
            ) {
             // console.debug("union");
              await reference.update({
                categories: fb.firestore.FieldValue.arrayUnion(categoryId),
              });
            }

            if (
              tag.categories &&
              tag.categories.includes(categoryId) &&
              !this.selectedTags.includes(tagId)
            ) {
             // console.debug("remove");
              await reference.update({
                categories: fb.firestore.FieldValue.arrayRemove(categoryId),
              });
            }
          }
        }
        return resolve("success");
      });
    },

    uplodaImage(categoryId) {
      return new Promise((resolve, reject) => {
        let image = this.fullImageFile;

        fb.app()
          .storage(`${process.env.VUE_APP_EL_OCHO_BK}-vive`)
          .ref()
          .child(`categories/${categoryId}/${image.name}`)
          .put(image)
          .then((res) => {
            return resolve("success");
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },

    addCategory() {
      if (this.validate) {
        this.saving = true;
        this.newCategory.city = this.city;
        this.newCategory.schedule = this.scheduleCopy ? this.scheduleCopy : {};
        this.newCategory.createdAt = new Date();
        this.newCategory.createdBy = this.user[".key"];
        this.newCategory.deleted = false;
        this.newCategory.active = this.newCategory.active ? true : false;

       // console.debug(this.newCategory);
        db.collection("oViveCategories")
          .add(this.newCategory)
          .then(async (ref) => {
            await this.updateTags(ref.id);

            if (this.imageFile) {
              await this.uplodaImage(ref.id, "smallImage");
            }

            if (this.fullImageFile) {
              await this.uplodaImage(ref.id, "fullImage");
            }

            this.saving = false;
            this.$emit("success");
          })
          .catch((err) => {
           // console.debug(err);
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },

    updateCategory() {
      if (this.validate) {
        let category = this.newCategory;
        delete category[`.key`];
        category.modifiedAt = new Date();
        category.modifiedBy = this.user[`.key`];

        this.saving = true;

        db.collection("oViveCategories")
          .doc(this.editedCategory[".key"])
          .update(category)
          .then(async () => {
            await this.updateTags(this.editedCategory[".key"]);
  
            if (this.fullImageFile) {
              await this.uplodaImage(this.editedCategory[".key"]);
            }
            this.saving = false;
            this.$emit("success");
          })
          .catch((err) => {
           console.log(err);
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            this.saving = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.v-tab {
  margin-left: 0 !important;
}
.theme--dark.v-tabs-items {
  background-color: #00000000;
}
.theme--dark.v-tabs > .v-tabs-bar {
  background-color: #ffffff;
  border-radius: 10px;
}
</style>
