<template>
  <div class="ma-0 pa-0 mb-0">
    <v-overlay class="loading-center" :value="save">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-card
            class="text-left pa-1 ma-1 flex-grow-1 mb-0"
            :color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'white'"
            elevation="0"
            style="border-radius: 17px !important; overflow: hidden"
          >
            <v-card-text class="text--primary mb-0">
              <h3>Días de la semana</h3>
              <v-divider></v-divider>
              <div class="days">
                <v-list>
                  <v-list-item-group v-model="item" color="primary">
                    <div
                      style="display: flex"
                      v-for="(day, i) in days"
                      :key="i"
                    >
                      <v-switch
                        @change="changeActive($event, day)"
                        v-model="day.active"
                      ></v-switch>
                      <v-list-item :key="i" @click="showSchedule(day.value)">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="day.text"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <transition
            enter-active-class="animated fadeIn"
            leave-active-class="animated"
            mode="out-in"
          >
            <v-card
              v-if="daySelected && waiting"
              class="text-left pa-1 ma-1 flex-grow-1 mb-0"
              :color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'white'"
              elevation="0"
              style="border-radius: 17px !important; overflow: hidden"
            >
              <v-card-text class="text--primary">
                <h3>Horarios del día seleccionado</h3>
                <v-divider></v-divider>
                <v-row class="mt-3 ml-3">
                  <v-col cols="2" sm="2" md="2"></v-col>
                  <v-col cols="5" sm="5" md="5"> Hora inicial </v-col>
                  <v-col cols="5" sm="5" md="5"> Hora final </v-col>
                </v-row>

                <div
                  v-for="(item, index) of schedule[daySelected]"
                  :key="index"
                  class="schedule"
                >
                  <v-row>
                    <v-col cols="2" md="2" sm="2">
                      <v-chip color="gray">
                        {{ index + 1 }}
                      </v-chip>
                    </v-col>

                    <v-col cols="5" md="5" sm="5">
                      <a-time-picker
                        :open.sync="item.activeOpens"
                        @change="changeOpens($event, index)"
                        format="HH:mm:ss"
                        :default-value="
                          typeof item.opensAt === 'string'
                            ? moment(`${item.opensAt}`, 'h:mm:ss')
                            : item.opensAt
                        "
                      >
                        <a-button
                          style="border-radius: 50px"
                          slot="addon"
                          block
                          size="small"
                          type="primary"
                          @click="handleClose(1, index)"
                        >
                          Aceptar
                        </a-button>
                      </a-time-picker>
                    </v-col>

                    <v-col cols="5" md="5" sm="5">
                      <a-time-picker
                        :open.sync="item.activeCloses"
                        @change="changeCloses($event, index)"
                        format="HH:mm:ss"
                        :default-value="
                          typeof item.closesAt === 'string'
                            ? moment(`${item.closesAt}`, 'h:mm:ss')
                            : item.closesAt
                        "
                      >
                        <a-button
                          style="border-radius: 50px"
                          slot="addon"
                          block
                          size="small"
                          type="primary"
                          @click="handleClose(2, index)"
                        >
                          Aceptar
                        </a-button>
                      </a-time-picker>
                    </v-col>

                    <div class="delete-schedule-container">
                      <p
                        @click="removeSchedule(index)"
                        class="delete-schedule mt-1 mb-2"
                      >
                        Eliminar
                      </p>
                    </div>
                  </v-row>
                </div>

                <div class="add-schedule">
                  <v-btn
                    @click="addSchedule"
                    class="mx-2 mt-3"
                    fab
                    dark
                    color="primary"
                  >
                    <v-icon>fas fa-plus</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </transition>
        </v-col>

        <!-- <v-col cols="12" sm="12" md="12">
			active
		</v-col> -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Lottie from "../components/Lottie";
import * as animationData from "../assets/ochoColor.json";
import { Button } from "ant-design-vue";
import { TimePicker } from "ant-design-vue";
import moment from "moment-timezone";
import "moment/locale/fr";

export default {
  name: "scheduler",
  props: ["schedule"],
  components: {
    lottie: Lottie,
    "a-time-picker": TimePicker,
    "a-button": Button,
  },
  data() {
    return {
      item: 0,
      hora: "",
      open: false,
      business: null,
      days: [
        { text: "Lunes", value: "monday", active: false },
        { text: "Martes", value: "tuesday", active: false },
        { text: "Miércoles", value: "wednesday", active: false },
        { text: "Jueves", value: "thursday", active: false },
        { text: "Viernes", value: "friday", active: false },
        { text: "Sábado", value: "saturday", active: false },
        { text: "Domingo", value: "sunday", active: false },
      ],
      time: null,
      menu2: false,
      daySelected: null,
      snackbar: false,
      snackbarText: null,
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      save: false,
      waiting: true,
      modalDelete: false,
      position: 0,
    };
  },
  methods: {
    moment,
    changeOpens(time, index) {
      this.schedule[this.daySelected][index].opensAt =
        moment(time).format("HH:mm:ss");
    },
    changeCloses(time, index) {
      this.schedule[this.daySelected][index].closesAt =
        moment(time).format("HH:mm:ss");
    },
    changeActive(e, day) {
      //console.log(e, day);
      this.schedule[`${day.value}Active`] = e;
    },
    showSchedule: function (day) {
      this.waiting = false;
      this.daySelected = day;
      setTimeout(() => {
        this.waiting = true;
      }, 100);
    },
    removeSchedule: function (position) {
      this.waiting = false;
      this.schedule[this.daySelected].splice(position, 1);
      setTimeout(() => {
        this.waiting = true;
      }, 100);
    },
    handleClose(order, index) {
      if (order == 1)
        this.schedule[this.daySelected][index].activeOpens = false;
      else this.schedule[this.daySelected][index].activeCloses = false;
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    addSchedule: function () {
      this.waiting = false;
      this.schedule[this.daySelected].push({
        opensAt: "00:00:00",
        closesAt: "00:00:00",
      });
      setTimeout(() => {
        this.waiting = true;
      }, 100);
    },
  },
  destroyed() {
    this.$store.commit("setSearchTerm", "");
    this.$store.commit("setSearch", "");
  },
  mounted() {
    this.daySelected = "monday";

    this.days.forEach((day) => {
      day.active = this.schedule[`${day.value}Active`];
    });
  },
};
</script>

<style scoped>
.schedule {
  width: 100%;
  margin-top: 10px;
}
.days {
  /* background-color: rgb(117, 115, 115); */
  background-color: white !important;
  width: 100%;
  min-height: 300px;
  margin-top: 10px;
}
.theme--light.v-list {
  background: white !important;
}
.add-schedule {
  display: flex;
  justify-content: flex-end;
}
.delete-schedule-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 10%;
}
.delete-schedule {
  margin: 0;
  cursor: pointer;
  padding-right: 5px;
  color: #f06f3d;
}
.save-btn {
  margin-top: 20px;
}
</style>
