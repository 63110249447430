<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">{{ section.name }}</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        style="max-height: 65vh; min-height: 65vh; overflow-y: scroll"
      >
        <v-container v-if="!loading" fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p>Nombre de la sección</p>
              <VueEmoji
                @input="nameChanged"
                width="700"
                ref="emoji"
                :value="localSection.name"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>Filtrar por</p>
              <!-- <input class="control-input" type="text" placeholder="Ingrese el tipo de sección" v-model="localSection.type"> -->
              <v-select
                class="mt-5"
                :items="filterBy"
                outlined
                tile
                v-model="localSection.type"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>No mostrar más de</p>
              <input
                class="control-input control-input-number"
                type="number"
                placeholder="Máximo comercios permitidos"
                v-model.number="localSection.maxAllowed"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>Que sean</p>
              <v-select
                class="mt-5"
                :items="operators"
                outlined
                tile
                v-model="localSection.operator"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>A este valor</p>
              <input
                class="control-input control-input-number"
                type="number"
                placeholder="Ingrese el número"
                v-model.number="localSection.number"
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <a-transfer
                :listStyle="{
                  height: '300px!important',
                   width: '45%',
                  background: 'white',
                }"
                :data-source="allTags"
                :titles="['Tags disponibles', 'Tags asignados']"
                :target-keys="localSection.tags"
                :selected-keys="selectedKeys"
                :show-search="true"
                @search="handleSearch"
                :render="(item) => item.title"
                :filter-option="filterOption"
                @change="handleChange"
                @selectChange="handleSelectChange"
                :locale="{
                  itemUnit: '',
                  itemsUnit: '',
                  notFoundContent: 'Lista vacía',
                  searchPlaceholder: 'Buscar sección',
                }"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions v-if="!loading">
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="start">
                <v-switch
                  v-model="localSection.active"
                  label="Sección activa"
                ></v-switch>
              </v-row>
            </v-col>

            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="end">
                <v-btn
                  @click="updateSection()"
                  class="save-btn mt-4"
                  color="primary"
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "../../../../components/Lottie";
import * as animationData from "../../../../assets/ochoColor.json";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import VueEmoji from "emoji-vue";
import transfer from "ant-design-vue/lib/transfer";

export default {
  name: "edit-section",
  props: ["section", "category"],
  components: {
    lottie: Lottie,
    VueEmoji,
    "a-transfer": transfer,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      menu: false,
      snackbarText: "",
      saving: false,
      loading: true,
      localSection: [],
      selectedTags: [],
      selectedKeys: [],
      allTags: [],
      newTag: "",
      filterBy: [
        { name: "Dias", value: "date" },
        { name: "Km", value: "distance" },
        { name: "Tags", value: "tag" },
      ],
      operators: [
        { name: "< (Menor que)", value: "<" },
        { name: "<= (Menor que o igual a)", value: "<=" },
        { name: "== (Igual a)", value: "==" },
        { name: "> (Mayor que)", value: ">" },
        { name: ">= (Mayor que o igual a)", value: ">=" },
        { name: "in (Igual a cualquiera de los siguientes)", value: "in" },
        {
          name: "array-contains (Un arreglo que contiene)",
          value: "array-contains",
        },
        {
          name: "array-contains-any (Un arreglo que contiene cualquiera de los siguientes)",
          value: "array-contains-any",
        },
      ],
    };
  },
  async mounted() {
    this.localSection = Object.assign({}, this.section);

    this.allTags = this.category.tags.map((item) => {
      return {
        title: item,
        key: item,
      };
    });
    this.loading = false;
  },

  methods: {
    handleChange(nextTargetKeys, direction, moveKeys) {
      this.localSection.tags = nextTargetKeys;
    },
    handleSearch(dir, value) {
      return value.toUpperCase();
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },

    filterOption(inputValue, option) {
      return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    },

    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    nameChanged(text) {
      this.localSection.name = text.data;
    },

    removeTag(tag) {
      this.localSection.tags = this.localSection.tags.filter(
        (item) => item != tag
      );
    },
    addTag() {
      if (this.newTag) {
        this.localSection.tags.push(this.newTag);
        this.newTag = "";
        this.menu = false;
      }
    },
    updateSection() {
      if (
        !this.localSection.name ||
        !this.localSection.maxAllowed ||
        !this.localSection.type ||
        !this.localSection.number ||
        !this.localSection.operator
      ) {
        this.snackbarText = "Debe llenar todos los campos.";
        this.snackbar = true;
        return;
      } else {
        this.saving = true;

        let section = {
          name: this.localSection.name,
          maxAllowed: this.localSection.maxAllowed,
          number: this.localSection.number,
          active: this.localSection.active,
          operator: this.localSection.operator,
          orderBy: this.localSection.orderBy,
          type: this.localSection.type,
          tags: this.localSection.tags,
          modifiedBy: this.$store.state.user[".key"],
          modifiedAt: new Date(),
        };

        db.collection(`categories/${this.category[".key"]}/sections`)
          .doc(this.section[".key"])
          .update(section)
          .then(() => {
            this.saving = false;

            this.$emit("success");
          })
          .catch((err) => {
           console.log(err);

            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
  },
};
</script>


<style>
.category-color {
  -webkit-appearance: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 50px;
  height: 45px !important;
  cursor: pointer;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
}

.category-color::-webkit-color-swatch {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 50px;
  padding: 0;
}
.category-color::-webkit-color-swatch-wrapper {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 50px;
  padding: 0;
}

.select-city {
  border-radius: 50px;
  padding: 0;
}

.control-input {
  /* height: 55px!important; */
  border-color: rgba(117, 117, 117, 0.582);
  /* color: black; */
}

.ant-input-number {
  height: 45px !important;
  width: 80% !important;
  border-radius: 10px !important;
  padding: 10px !important;
}

.new-tag {
  background-color: white;
  padding: 10px;
}

.emoji-wysiwyg-editor {
  border: 1px solid rgba(90, 89, 89, 0.432);
  border-radius: 10px !important;
  padding: 10px;
  margin-top: 10px;
  color: black;
}

.emoji-wysiwyg-editor:focus {
  outline: none !important;
  border: 1px solid #f06f3d;
  box-shadow: 0 0 5px #f06f3d;
}

.v-text-field--outlined fieldset {
  border-radius: 10px !important;
}

.tags-list {
  padding: 10px;
  border: 1px solid #3331315e;
  border-radius: 50px;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-height: 50px;
  /* overflow-x: scroll; */
}

.emoji-picker-icon {
  margin-top: 5px;
}
</style>