<template>
  <v-container fluid class="mt-6">
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-row>
          <v-col class="images py-6" cols="4" sm="4" md="4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div
                  v-on="on"
                  @click.prevent="showFileChooser('fullImage')"
                  class="full-image"
                >
                  <v-img
                    :src="fullImage || defaultImage"
                    :lazy-src="fullImage || defaultImage"
                    style="border-radius: 10px"
                  />
                </div>
              </template>
              <span
                >Imagen utilizada cuando la categoría no muestra el nombre</span
              >
            </v-tooltip>
          </v-col>

          <v-col id="data" class="mb-0 mt-0 pb-0 pt-0" cols="8" sm="8" md="8">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  rounded
                  label="Nombre de la categoría"
                  filled
                  hide-details=""
                  style="border-radius: 10px"
                  placeholder="Ingrese el nombre"
                  v-model="newCategory.name"
                />
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-select
                  :items="sizes"
                  hide-detail
                  label="Tamaño de la categoría"
                  rounded
                  v-model="newCategory.size"
                  placeholder="Seleccione el tamaño de la categoría"
                  filled
                  style="border-radius: 10px"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <p>Descripción</p>
                <v-textarea
                  rows="4"
                  v-model="newCategory.description"
                  rounded
                  filled
                  hide-details=""
                  style="border-radius: 10px"
                />
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <p>Color de categoría</p>
                <input
                  class="category-color"
                  type="color"
                  style="border-radius: 10px"
                  placeholder=""
                  v-model="newCategory.color"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <input
      class="upload"
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="imageChanged"
    />
  </v-container>
</template>

<script>
import { InputNumber, Button, TimePicker } from "ant-design-vue";

export default {
  props: ["newCategory"],
  name: "category-general",

  components: {
    aInputNumber: InputNumber,
    aButton: Button,
    aTimePicker: TimePicker,
  },

  data() {
    return {
      imgPreview: "",
      fullImage: "",
      defaultImage:
        "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df",
      menu: false,
      newTag: "",
      imageType: "",
      smallImageFile: null,
      imagesHeight: 0,
      fullImageFile: null,
      extraFeeIsFixed: [
        { text: "Porcentual", value: false },
        { text: "Fijo", value: true },
      ],
      extraFees: [
        {
          extraFeeIsFixed: "",
          extraFeeName: "",
          extraFeeSum: "",
          active: false,
        },
      ],
      sizes: [
        {
          value: "small",
          text: "Pequeño",
        },
        {
          value: "medium",
          text: "Medio",
        },
        {
          value: "large",
          text: "Grande",
        },
      ],
    };
  },

  methods: {
    imageChanged(e) {
      if (!e.target.files[0]) {
        return;
      }

      if (this.imageType == "smallImage") {
        this.smallImageFile = e.target.files[0];
      } else {
        this.fullImageFile = e.target.files[0];
      }

      if (
        this.imageType == "smallImage" &&
        this.smallImageFile &&
        this.smallImageFile.type.indexOf("image/") === -1
      ) {
       console.log("tipo de archivo no permitido");
        this.smallImageFile = null;
      }

      if (
        this.imageType == "fullImage" &&
        this.fullImageFile &&
        this.fullImageFile.type.indexOf("image/") === -1
      ) {
       console.log("tipo de archivo no permitido");
        this.fullImageFile = null;
      }

      this.$emit("imageSelected", {
        imageType: this.imageType,
        smallImageFile: this.smallImageFile,
        fullImageFile: this.fullImageFile,
      });

      var reader = new FileReader();

      reader.onload = (e) => {
        if (this.imageType == "smallImage") {
          this.imgPreview = e.target.result;
        } else {
          this.fullImage = e.target.result;
        }
      };

      reader.readAsDataURL(
        this.imageType == "smallImage"
          ? this.smallImageFile
          : this.fullImageFile
      );
    },

    showFileChooser(e) {
      this.imageType = e;
      this.$refs.input.click();
    },
  },

  mounted() {
    this.imgPreview = this.newCategory.cover
      ? this.newCategory.cover.original
      : "";

    this.fullImage = this.newCategory.cover
      ? this.newCategory.cover.original
      : "";

    this.imagesHeight = document.getElementById("data").clientHeight;
  },
};
</script>

<style scoped lang="scss">
.category-color {
  -webkit-appearance: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 50px;
  height: 45px !important;
  cursor: pointer;
}

.category-color::-webkit-color-swatch {
  border-radius: 50px;
  padding: 0;
}
.category-color::-webkit-color-swatch-wrapper {
  border-radius: 50px;
  padding: 0;
}

.select-city {
  border-radius: 50px;
  padding: 0;
}

.category-image {
  height: 50%;
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    cursor: pointer;
    max-height: 90%;
    object-fit: contain;
  }
}

.upload {
  display: none;
}

.images {
  border: 1px solid rgba(156, 156, 156, 0.168);
  border-radius: 10px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.full-image {
  cursor: pointer;
}
.flex-center {
  display: flex;

  height: 100%;
  position: relative;
  justify-content: center;
  align-content: center;
}
</style>
