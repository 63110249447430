<template>
  <v-container fluid>
    <v-row class="fee" v-for="(fee, index) of extraFees" :key="index">
      <v-col cols="12" sm="2" md="3">
        <v-label>Tipo de recargo</v-label>
        <v-autocomplete
          v-model="fee.extraFeeIsFixed"
          :items="extraFeeIsFixed"
          placeholder="Seleccione tipo de recargo"
          outlined
          rounded
          class="mt-3"
        />
      </v-col>

      <v-col cols="12" sm="2" md="3">
        <v-label>Nombre del recargo</v-label>
        <v-text-field
          rounded
          outlined
          class="mt-3"
          placeholder="Nombre del recargo"
          v-model="fee.extraFeeName"
        />
      </v-col>

      <v-col cols="12" sm="2" md="3">
        <v-label>Valor del recargo</v-label>
        <v-text-field
          rounded
          outlined
          class="mt-3"
          @keypress="isNumber($event, fee.extraFeeSum)"
          placeholder="Valor del recargo"
          v-model.number="fee.extraFeeSum"
        />
      </v-col>
      <!-- <v-col class="mt-5" cols="12" sm="2" md="2">
        <v-switch
          @change="update"
          v-model="fee.applyToDelivery"
          label="Aplica a delivery"
        />
      </v-col>

      <v-col class="mt-5" cols="12" sm="2" md="2">
        <v-switch
          @change="update"
          v-model="fee.applyToPickUp"
          label="Aplica a PickUp"
        />
      </v-col> -->

      <v-col class="mt-5" cols="12" sm="1" md="1">
        <v-switch @change="update" v-model="fee.active" label="Activa" />
      </v-col>
      <v-col cols="12" sm="1" md="1">
        <v-btn
          @click="removeFee(index)"
          small
          fab
          :elevation="0"
          :dark="$vuetify.theme.dark"
          color="red darken-4"
          class="white--text mt-8 ml-5"
        >
          <v-icon dark>fa-trash</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-col cols="12" sm="12" md="12">
      <a @click="addFees()"> <i class="fas fa-plus"></i> Agregar tarifa </a>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: "category-fees",
  props: ["newCategory"],

  data() {
    return {
      extraFeeIsFixed: [
        { text: "Porcentual", value: false },
        { text: "Fijo", value: true },
      ],
      extraFees: [
        {
          extraFeeIsFixed: "",
          extraFeeName: "",
          extraFeeSum: "",
          active: false,
        },
      ],
    };
  },

  watch: {
    extraFees(e) {
      //console.log("changedddd");
      this.$emit("change", e);
    },
  },

  methods: {
    addFees() {
      this.extraFees.push({
        extraFeeIsFixed: "",
        extraFeeName: "",
        extraFeeSum: "",
        active: false,
      });
    },

    update() {
      this.$emit("change", this.extraFees);
    },

    removeFee(index) {
      this.extraFees = this.extraFees.filter((item, i) => i != index);

      if (this.extraFees.length == 0) {
        this.extraFees = [
          {
            extraFeeIsFixed: "",
            extraFeeName: "",
            extraFeeSum: "",
            active: false,
          },
        ];
      }
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.extraFees = this.newCategory.extraFees
      ? this.newCategory.extraFees
      : this.extraFees;
  },
};
</script>


<style scoped>
.fee {
  background-color: #b7b7b726;
  border-radius: 20px;
  margin-top: 20px;
}
</style>