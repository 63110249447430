<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Nueva sección</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container v-if="!loading" fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p>Nombre de la sección</p>
              <VueEmoji
                @input="nameChanged"
                width="700"
                ref="emoji"
                :value="section.name"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>Filtrar por</p>
              <v-select
                class="mt-5"
                :items="filterBy"
                placeholder="Filtrar por"
                outlined
                tile
                v-model="section.type"
                item-text="name"
                item-value="value"
              ></v-select>
              <!-- <input class="control-input" type="text" placeholder="Ingrese el tipo de sección" v-model="section.type"> -->
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>No mostrar más de</p>
              <input
                class="control-input control-input-number"
                type="number"
                placeholder="Máximo comercios permitidos"
                v-model.number="section.maxAllowed"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>Que sean</p>
              <v-select
                class="mt-5"
                :items="operators"
                placeholder="Elija operador"
                outlined
                tile
                v-model="section.operator"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>A este valor</p>
              <input
                class="control-input control-input-number"
                type="number"
                placeholder="Ingrese el valor máximo permitido"
                v-model.number="section.number"
              />
            </v-col>

            <!-- <v-col cols="12" sm="6" md="6">
                        <p>Ordenar por</p>
                        <input class="control-input" type="text" placeholder="Ingrese el campo a ordenar" v-model="section.orderBy">
                    </v-col> -->

            <v-col cols="12" sm="12" md="12">
              <a-transfer
                :listStyle="{
                  height: '300px!important',
                 width: '45%',
                  background: 'white',
                }"
                :data-source="allTags"
                :titles="['Tags disponibles', 'Tags asignados']"
                :target-keys="section.tags"
                :selected-keys="selectedKeys"
                :show-search="true"
                @search="handleSearch"
                :render="(item) => item.title"
                :filter-option="filterOption"
                @change="handleChange"
                @selectChange="handleSelectChange"
                :locale="{
                  itemUnit: '',
                  itemsUnit: '',
                  notFoundContent: 'Lista vacía',
                  searchPlaceholder: 'Buscar sección',
                }"
              />

              <!-- <p>Tags</p> -->
              <!-- <div class="tags-list">
                            <v-chip
                                v-for="(item, index) of section.tags" :key="index" 
                                class="pa-4 mr-3"
                                style="padding:10px"                                       
                                color="primary"
                                close
                                @click:close="removeTag( item )"
                                >
                                {{item}}
                            </v-chip>
                        </div> -->
            </v-col>
            <!-- <v-col class="mt-9" cols="12" sm="2" md="2">
                         <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-width="200"
                            offset-x
                            >
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="ma-2"  color="gray">
                                    <v-icon left>fas fa-plus</v-icon> Agregar
                                </v-btn>
                            </template>
                                <div class="new-tag">
                                    <p class="title ma-0" style="color:#f06a25;">Agregar tag</p>

                                    <input type="text" placeholder="Nombre del tag" class="control-input" v-model="newTag">
                                    <v-btn style="border-radius:15px" block class="mt-3" color="primary" dark @click="addTag">Guardar</v-btn>    
                                </div>
                        </v-menu>
                    </v-col> -->
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions v-if="!loading">
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="start">
                <v-switch
                  v-model="section.active"
                  label="Sección activa"
                ></v-switch>
              </v-row>
            </v-col>

            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="end">
                <v-btn
                  @click="addSection()"
                  class="save-btn mt-4"
                  color="primary"
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../../components/Lottie";
import * as animationData from "../../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";
import VueEmoji from "emoji-vue";
import transfer from "ant-design-vue/lib/transfer";

export default {
  name: "edit-section",
  props: ["category", "sectionsLength"],
  components: {
    lottie: Lottie,
    VueEmoji,
    "a-transfer": transfer,
  },
  data() {
    return {
      animationSpeed: 1.1,
      selectedKeys: [],
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      menu: false,
      snackbarText: "",
      saving: false,
      loading: true,
      allTags: [],
      newTag: "",
      section: {
        name: "",
        maxAllowed: "",
        orderBy: "",
        number: "",
        type: "",
        tags: [],
        operator: "",
        createdAt: "",
        createdBy: this.$store.state.user[".key"],
        deleted: false,
        active: false,
      },
      filterBy: [
        { name: "Dias", value: "date" },
        { name: "Km", value: "distance" },
        { name: "Tags", value: "tag" },
      ],
      operators: [
        { name: "< (Menor que)", value: "<" },
        { name: "<= (Menor que o igual a)", value: "<=" },
        { name: "== (Igual a)", value: "==" },
        { name: "> (Mayor que)", value: ">" },
        { name: ">= (Mayor que o igual a)", value: ">=" },
        { name: "in (Igual a cualquiera de los siguientes)", value: "in" },
        {
          name: "array-contains (Un arreglo que contiene)",
          value: "array-contains",
        },
        {
          name: "array-contains-any (Un arreglo que contiene cualquiera de los siguientes)",
          value: "array-contains-any",
        },
      ],
    };
  },
  async mounted() {
    this.loading = false;

    this.allTags = this.category.tags.map((item) => {
      return {
        title: item,
        key: item,
      };
    });
  },

  methods: {
    handleChange(nextTargetKeys, direction, moveKeys) {
      this.section.tags = nextTargetKeys;
    },
    handleSearch(dir, value) {
      return value.toUpperCase();
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },

    filterOption(inputValue, option) {
      return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    },

    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    nameChanged(text) {
      this.section.name = text.data;
    },

    removeTag(tag) {
      this.section.tags = this.section.tags.filter((item) => item != tag);
    },
    addTag() {
      if (this.newTag) {
        this.section.tags.push(this.newTag);
        this.newTag = "";
        this.menu = false;
      }
    },
    addSection() {
      if (
        !this.section.name ||
        !this.section.maxAllowed ||
        !this.section.type ||
        !this.section.number ||
        !this.section.operator
      ) {
        this.snackbarText = "Debe llenar todos los campos.";
        this.snackbar = true;
        return;
      } else {
        this.section.createdAt = new Date();
        this.section.position = this.sectionsLength + 1;

        this.saving = true;

        db.collection(`oViveCategories/${this.category[".key"]}/sections`)
          .add(this.section)
          .then(() => {
            this.saving = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
  },
};
</script>


<style>
.category-color {
  -webkit-appearance: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 50px;
  height: 45px !important;
  cursor: pointer;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
}

.category-color::-webkit-color-swatch {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 50px;
  padding: 0;
}
.category-color::-webkit-color-swatch-wrapper {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 50px;
  padding: 0;
}

.select-city {
  border-radius: 50px;
  padding: 0;
}

.control-input {
  /* height: 55px!important; */
  border-color: rgba(117, 117, 117, 0.582);
  /* color: black; */
}

.ant-input-number {
  height: 45px !important;
  width: 80% !important;
  border-radius: 10px !important;
  padding: 10px !important;
}

.new-tag {
  background-color: white;
  padding: 10px;
}

.emoji-wysiwyg-editor {
  border: 1px solid rgba(90, 89, 89, 0.432);
  border-radius: 10px !important;
  padding: 10px;
  margin-top: 10px;
  color: black;
}

.emoji-wysiwyg-editor:focus {
  outline: none !important;
  border: 1px solid #f06f3d;
  box-shadow: 0 0 5px #f06f3d;
}

.v-text-field--outlined fieldset {
  border-radius: 10px !important;
}

.emoji-picker-icon {
  margin-top: 5px;
}
</style>